<template>
  <div class="h-screen flex w-full bg-img">
    <div
      class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center"
    >
      <img
        src="@/assets/images/pages/not-authorized.png"
        alt="graphic-not-authorized"
        class="mx-auto mb-4"
      />
      <h1 class="sm:mx-0 mx-4 sm:mb-12 mb-8 text-5xl d-theme-heading-color">
        You are not authorized!
      </h1>
      <p class="sm:mx-0 mx-4 sm:mb-12 mb-8 d-theme-text-inverse">
        paraphonic unassessable foramination Caulopteris worral Spirophyton
        encrimson esparcet aggerate chondrule restate whistler shallopy
        biosystematy area bertram plotting unstarting quarterstaff.
      </p>
      <vs-button size="large" @click="goBack">Back to Home</vs-button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goBack() {
      // If JWT login
      if (localStorage.getItem('accessToken')) {
        localStorage.removeItem('accessToken');
        this.$router.push('/iniciar-sesion').catch(() => {});
      }

      // Change role on logout. Same value as initialRole of acj.js
      /* this.$acl.change('public'); */
      localStorage.removeItem('userInfo');

      // This is just for demo Purpose. If user clicks on logout -> redirect
      this.$router.push('/iniciar-sesion').catch(() => {});
    },
  },
};
</script>
